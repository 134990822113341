import './components/jquery.responsifyBgImg';
import './components/checkflexgap';
import './vendor/owl.carousel.min';
import './components/isInViewport';
import './vendor/lity.min';

(function($) {

    const $body = $('body');
    let submenuDisplay;
    let menuDisplay;
    let winW  = $(window).width();

	 $('body').on('click', '.js-toggle-nav', function() {
        let $this = $(this);

        if ($this.data('action') == 'swap') {
            hideMenu();
            displayMenu($this);
        }

        if ($this.data('action') == 'toggle') {
            if (menuDisplay == true) {
                hideMenu();
            } else {
                displayMenu($this);
            }
        }
        
    });

    function displayMenu(el) {
        let panelid = el.data('panel-id');
        //let $panel = $(panelid);

        $body.addClass('menu-open');
        menuDisplay = true;
    }

    function hideMenu() {
        $body.removeClass('menu-open');
        $('.menu-panel').removeClass('menu-submenu-open');
        $('.sub-menu').removeClass('sub-menu--active');
        menuDisplay = false;
    }

    // Offcanvas Submenu
    if (winW < 1024) {

        $('body').on('click', '.js-submenu', function(e) {

            e.preventDefault();
            e.stopPropagation();
            let $this = $(this);
            let action = $this.data('action');
            let level = $this.data('level');
            let current = $this.data('current');

            if (submenuDisplay == true && action == 'close') {
                hideSubMenu(current);
            }

            if (action == 'open') {
                displaySubMenu($this, level);
            }
        });

        function displaySubMenu(el, lvl) {
            $body.addClass('submenu-open');
            el.parent().next('.sub-menu').toggleClass('sub-menu--active');
            $('#submenu-close').data('current', lvl);
            console.log($('#submenu-close').data('current'));
            submenuDisplay = true;
        }

        function hideSubMenu(lvl) {
            if (lvl == 0) {
                $body.removeClass('submenu-open');
                submenuDisplay = false;
            } 

            $('.sub-menu.level-' + lvl).removeClass('sub-menu--active');
            $('#submenu-close').data('current', lvl - 1);
            console.log($('#submenu-close').data('current'));
        }
    }

    // Sticky Header

    const observer = new IntersectionObserver( 
        ([e]) => e.target.toggleAttribute('stuck', e.intersectionRatio < 1),
        {threshold: [1]}
    );
    
    observer.observe(document.getElementById('site-header'));

    // Search

    var searchDisplay;

    $('.js-search button').on('click',function() {
        var $this = $(this);
        if (searchDisplay == true) {
            hideSearch($this);
        } else {
            displaySearch($this);
        }
    });

    function displaySearch($trigger) {
        $trigger.closest('.js-search').addClass('search-open');
        $trigger.closest('input[type="text"').focus();
        searchDisplay = true;
    }

    function hideSearch($trigger) {
        $trigger.closest('.js-search').removeClass('search-open');
        searchDisplay = false;
    }

    
    // Owl Carousel

    const owlHeroSlider = $('.owl-hero-slider');

    const next = '<svg class="svgicon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/gsc/dist/img/icons.svg#arrow-right"></use></svg>';
    const prev = '<svg class="svgicon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/gsc/dist/img/icons.svg#arrow-left"></use></svg>';

    
    owlHeroSlider.owlCarousel({
        loop:true,
        nav:true,
        dots: false,
        autoplay: false,
        smartSpeed: 1200,
        items:1,
        navText: [prev,next],
        //animateOut: 'fadeOut',
        slideTransition: 'cubic-bezier(0.29, 0.74, 0.41, 0.91)'
    });

    const owlAccreditationCarousel = $('.owl-accreditation-carousel');

    owlAccreditationCarousel.owlCarousel({
        loop:false,
        nav:true,
        dots: false,
        autoplay: false,
        navText: [prev,next],
        smartSpeed: 750,
        responsive:{
            0:{
                items:1
            },
            480:{
                items:2,
                margin:36
            },
            960: {
                items:4,
                margin:36
            },
            1200: {
                items:5,
                margin:36
            },
            1400: {
                items:6,
                margin:36
            }
        }
    });

    const owlInternalSlider = $('.owl-internal-slider');

    owlInternalSlider.owlCarousel({
        loop: true,
        margin:0,
        nav:true,
        dots: true,
        autoplay: true,
        smartSpeed: 1000,
        autoplayTimeout: 9000,
        items:1
    });

    // Scroll to ID

    $('.js-scrollto').on('click', function () {
        var id = $(this).data('target');
        scrollTo(id);
    });

    function scrollTo(id){
        var el;
        var scrollPos;
        var winW = $(window).width();

        if (id === 'top') {
            scrollPos = 0;
        } else {
            el = $(id);
            scrollPos = el.offset().top;
        }

        $('html,body').animate({scrollTop: scrollPos},'slow');
    }

    // Responsive Background Images

    $('.js-responsive-bg').responsifybgimg();

    // Animate on Scroll

    $('.js-visible:in-viewport(0)').addClass('is-visible');

    $(window).on('scroll', function() {
        $('.js-visible:in-viewport(-250)').addClass('is-visible');
    });

    // Tabs

    $('.js-tab').on('click', function() {
        var $this = $(this);
        if (!$this.hasClass('tabbed-panels__tab--active')) {
            $('.tabbed-panels__tab--active').removeClass('tabbed-panels__tab--active');
            $('.tabbed-panels__item--active').removeClass('tabbed-panels__item--active is-visible');
            var target = $this.data('target');
            var tab_index = $this.data('tab-index');

            if(!tab_index && tab_index != 0) {
                $this.addClass('tabbed-panels__tab--active');
            } else {
                var i = tab_index;
                $('.tabbed-panels__tab').eq(i).addClass('tabbed-panels__tab--active');
            }
            
            changePanel(target);
        }
    });

    function changePanel(panel) {
        
        $(panel).addClass('tabbed-panels__item--active');
        
        setTimeout(function() {
            $(panel).addClass('is-visible');
        },200);
    }

    // Dropdown List

    $('.js-dropdown-list').on('click', function() {
        var $this = $(this);

        $this.parent().toggleClass('dropdown-list__item--is-open');
    });

     // Generic Toggles

     $('.js-toggle').on('click',function() {    
        let target = $(this).data('target');
        if (target == 'self') {
            $(this).toggleClass('active');
        } else {
            let $target = $(target);
            $target.toggleClass('active');
        }
    });

      // Read More Container

      function generateReadmore() {    
        
        var $el = $('.readmore');
        var itemHeight;
        var readMore =  '<a href="javascript:void(0);" class="js-readmore__button readmore__link" title="Read More"><span class="readmore__link__text">Read More</span> <svg class="readmore__icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/gsc/dist/img/icons.svg#angle-down"></use></svg></a>';

        $el.each( function( index, element ){
            var $this = $(this);
            
            if ($this.find('.readmore__link').length > 0 ) {
                return;
            }

            itemHeight = $this.find('.readmore__text').height();
            console.log(itemHeight);
            if (itemHeight > 86) {
                $this.addClass('hidden-content');
                $this.find('.readmore__actions').prepend(readMore);
            }
        });

        $('.js-readmore__button').click(function() {
            var $textel = $(this).find('.readmore__link__text');

            ($textel.text() == 'Read More') ? $textel.text('Read Less').parent().attr('title', 'Read Less') : $textel.text('Read More').parent().attr('title', 'Read More');

            $(this).parent().prev('.js-readmore__container').toggleClass('open');
        });
    }

    generateReadmore();

})(jQuery);